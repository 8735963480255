import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Faq } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { FaqService } from '../services/faq.service';

@Component({
	selector: 'pk-broker-faq-view',
	templateUrl: './faq-view.component.html',
	styleUrls: ['./faq-view.component.scss'],
})
export class FaqViewComponent extends AbstractPageForm {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private faqService: FaqService,
	) {
		super();
		this.route.paramMap.subscribe((paramMap: ParamMap) => {
			const faqId = paramMap.get('faqId');

			if (faqId) {
				this.faqService.getFaqArticle(faqId);
			}
		});
	}

	get faqArticle(): Faq {
		return this.faqService.faqArticle;
	}
	get loadingArticle(): boolean {
		return this.faqService.loadingArticle;
	}
	get relatedArticles(): Faq[] {
		return this.faqService.relatedArticles;
	}

	public showFaqArticle(faq: Faq): void {
		this.router.navigate(['/faq', 'view', faq.id], { relativeTo: this.route });
	}
}
