import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Breadcrumb, FaqCategory, FaqSearchResult } from 'src/app/shared/models';
import { AbstractPage } from 'src/app/shared/pages/page.abstract';
import { SupportCenterSupportCallComponent } from 'src/app/support-center/modals/support-center-support-call/support-center-support-call.component';
import { Faq } from '../../shared/models';
import { FaqService } from '../services/faq.service';

@Component({
	selector: 'pk-broker-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent extends AbstractPage implements OnInit {
	public static breadcrumbs = [
		{
			text: 'Dashboard',
			route: '/contract',
		},
		{
			text: 'FAQ',
			isLast: true,
		},
	] as Breadcrumb[];

	constructor(
		private modalService: BsModalService,
		private router: Router,
		private route: ActivatedRoute,
		public faqService: FaqService,
	) {
		super(FaqComponent.breadcrumbs, 'FAQ');
	}

	async ngOnInit(): Promise<void> {
		await this.faqService.getFaqPageData();
		this.loading = false;
	}

	get faqArticle(): Faq {
		return this.faqService.faqArticle;
	}
	get faqData(): Subject<any> {
		return this.faqService.faqData;
	}
	get loadingFaqSearch(): boolean {
		return this.faqService.loadingFaqSearch;
	}
	get loadingFaqCategorySearch(): boolean {
		return this.faqService.loadingFaqCategorySearch;
	}
	get faqResult(): { faqSearchResults: FaqSearchResult[] } {
		return this.faqService.faqResult;
	}
	get faqCategory(): FaqCategory {
		return this.faqService.faqCategory;
	}
	get faqCategories(): FaqCategory[] {
		return this.faqService.faqCategories;
	}
	get defaultFaqs(): Faq[] {
		return this.faqService.defaultFaqs;
	}
	get loadingArticle(): boolean {
		return this.faqService.loadingArticle;
	}

	public search(): void {
		if (this.faqService.criteria.value.length > 2) {
			this.router.navigate(['search'], { relativeTo: this.route });
			this.faqData.next({ search: this.faqService.criteria.value });
		}
	}

	public showFaqArticle(faq: Faq): void {
		this.router.navigate(['view', faq.id], { relativeTo: this.route });
	}

	public openSupportCallModal(): void {
		this.modalService.show(SupportCenterSupportCallComponent, {
			class: 'pk-modal pk-modal-support-center modal-dialog-centered',
		});
	}
}
