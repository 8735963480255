import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SupportCenterModule } from '../support-center/support-center.module';
import { FaqResultsComponent } from './faq-results/faq-results.component';
import { FaqRoutingModule } from './faq-routing.module';
import { FaqViewComponent } from './faq-view/faq-view.component';
import { FaqComponent } from './faq/faq.component';
import { FaqSearchModalFeedbackComponent } from './modals/faq-search-modal/faq-search-modal-feedback/faq-search-modal-feedback.component';
import { FaqSearchModalRelatedArticlesComponent } from './modals/faq-search-modal/faq-search-modal-related-articles/faq-search-modal-related-articles.component';
import { FaqSearchModalComponent } from './modals/faq-search-modal/faq-search-modal.component';

@NgModule({
	imports: [CommonModule, SharedModule, FaqRoutingModule, SupportCenterModule],
	declarations: [
		FaqComponent,
		FaqSearchModalComponent,
		FaqSearchModalFeedbackComponent,
		FaqSearchModalRelatedArticlesComponent,
		FaqResultsComponent,
		FaqViewComponent,
	],
	exports: [FaqSearchModalComponent],
})
export class FaqModule {}
