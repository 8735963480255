import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Faq, FaqCategory, FaqSearchResult } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { FaqService } from '../services/faq.service';

@Component({
	selector: 'pk-broker-faq-results',
	templateUrl: './faq-results.component.html',
	styleUrls: ['./faq-results.component.scss'],
})
export class FaqResultsComponent extends AbstractPageForm {
	public usedQueryCriteria: boolean;

	constructor(
		private faqService: FaqService,
		private router: Router,
		private route: ActivatedRoute,
	) {
		super();
		this.route.paramMap.subscribe((paramMap: ParamMap) => {
			const type = paramMap.get('type');
			const id = paramMap.get('id');

			this.usedQueryCriteria = false;
			if (type === 'category') {
				this.faqService.chooseFaqCategory(id);
			} else if (type === 'search') {
				this.usedQueryCriteria = true;
			}
		});
	}

	get faqArticle(): Faq {
		return this.faqService.faqArticle;
	}
	get faqResult(): { faqSearchResults: FaqSearchResult[] } {
		return this.faqService.faqResult;
	}
	get faqCategory(): FaqCategory {
		return this.faqService.faqCategory;
	}
	get faqCategories(): FaqCategory[] {
		return this.faqService.faqCategories;
	}
	get loadingFaqSearch(): boolean {
		return this.faqService.loadingFaqSearch;
	}
	get loadingFaqCategorySearch(): boolean {
		return this.faqService.loadingFaqCategorySearch;
	}

	public showFaqArticle(faq: Faq): void {
		this.router.navigate(['/faq', 'view', faq.id]);
	}
}
